import Label from 'DesignSystem/Typography/Label/Label';
import React from 'react';
import { styled } from 'Theme/stitches.config';

type PropTypes = {
  children: React.ReactNode;
  legend?: string;
  helperText?: string;
  onChange?: React.ChangeEventHandler<HTMLFieldSetElement>;
};

const Fieldset = ({ children, legend, helperText, onChange }: PropTypes) => {
  return (
    <Field onChange={onChange}>
      {(legend || helperText) && (
        <TopWrapper>
          {legend && <StyledLegend>{legend}</StyledLegend>}
          {helperText && <Label>{helperText}</Label>}
        </TopWrapper>
      )}
      {children}
    </Field>
  );
};

export default Fieldset;

const Field = styled('fieldset', {
  width: 'max-content',
  p: 0,
  '> div': {
    '&:not(:last-child)': {
      mb: '$s100',
    },
  },
  my: '$s100',
});

const StyledLegend = styled('legend', {
  color: '$onSurface',
  fontWeight: '$fontWeightRegular',
  fontFamily: 'fontSemiBold',
  fontSize: '$fontSizes$fontSize100',
  lineHeight: '$lh24',
  mb: '$s150',
});

const TopWrapper = styled('div', {
  mb: '$s100',
});
