import SearchTranslations from 'Models/Translations/SearchTranslations.interface';

export const mapSearchTranslations = (
  translations: SearchTranslations,
  value: string,
  isUnit = false
) => {
  if (!translations || !value) return '';
  const translation =
    translations[
      ('facet' +
        value.replace('$', '').replace('.', '') +
        (isUnit ? 'Unit' : '')) as keyof SearchTranslations
    ];
  if (translation === undefined) return value;
  return translation;
};
